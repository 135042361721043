import { AgendaItem, Maybe, Meeting } from '../../../graphql/types';
import { useEffect, useState } from 'react';

import { MdOutlineDeleteOutline } from 'react-icons/md';
import { classNames } from '../../../helpers/tailwindHelper';
import { useIsShared } from '../../reports/hooks/use-report-location';
import { useToggleAgenda } from '../hooks/use-toggle-agenda';
import { useTrackMeetingEvent } from '../../tracking/hooks/use-track-event';

type AgendaListItemProps = {
  data: Maybe<AgendaItem>;
  handleDelete: (itemId: string) => void;
  meeting?: Meeting;
  setExpanded: React.Dispatch<
    React.SetStateAction<AgendaItem | null | undefined>
  >;
  itemNotes?: string | null;
};

const AgendaListItem = ({
  data,
  handleDelete,
  meeting,
  setExpanded,
  itemNotes,
}: AgendaListItemProps) => {
  const { isShared } = useIsShared();
  const { loading, toggleAgendaItem } = useToggleAgenda(meeting);
  const [reviewed, setReviewed] = useState(false);
  const track = useTrackMeetingEvent({ meeting, page: 'agenda_list_item' });

  useEffect(() => {
    if (meeting?.agendaItems && data?.id && !loading) {
      const isReviewed = meeting.agendaItems.includes(data.id);
      if (isReviewed !== reviewed) {
        setReviewed(isReviewed);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, meeting?.agendaItems]);

  const toggleReviewed = () => {
    if (data) {
      setReviewed(!reviewed);
      toggleAgendaItem(data);
      track('agenda_item_reviewed', `agendaId_${data.id}`);
    }
  };

  if (!data) return null;

  return (
    <li
      key={data.id}
      className='col-span-1 flex rounded-md shadow-sm text-xs'
      data-tip={data.name}
    >
      <button
        className={classNames(
          reviewed ? 'bg-green-500/60' : 'bg-blue-500/60',
          'flex w-8 flex-shrink-0 items-center justify-center rounded-l-md text-white'
        )}
        disabled={isShared || loading}
        onClick={toggleReviewed}
      >
        {reviewed ? '✔' : ''}
      </button>
      <div className='flex flex-1 h-8 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white'>
        <div className='flex-1 truncate px-4 py-2'>
          <button
            disabled={isShared || loading}
            onClick={() => setExpanded(data)}
          >
            {data.name}
            {data.isDefault ? <sup>*</sup> : ''}
          </button>
          <p className='text-gray-500'>{data.notes}</p>
          {itemNotes ? (
            <span className='text-xs text-gray-600'>{itemNotes}</span>
          ) : null}
        </div>
        <div className='flex-shrink-0 pr-2'>
          {!data.isDefault && (
            <button
              className='hover:text-red-500 ml-1.5 mr-2'
              disabled={isShared || loading}
              onClick={() => handleDelete(data?.id as string)}
            >
              <MdOutlineDeleteOutline className='text-red-500 h-5 w-5' />
            </button>
          )}
        </div>
      </div>
    </li>
  );
};

export { AgendaListItem };
