import { Button } from '@/components/ui/button';
import { FaMagic } from 'react-icons/fa';
import clsx from 'clsx';
import { useFlags } from '@/context/feature-flags';

interface BtnGenerateNotesProps {
  connection?: WebSocket | null;
  triggerGeneration?: () => void;
}

export const BtnGenerateNotes = ({
  connection,
  triggerGeneration,
}: BtnGenerateNotesProps) => {
  const { flags } = useFlags();
  const aiNotesFlag = flags?.['ai-notes'];

  if (!aiNotesFlag) return null;

  return (
    <Button
      variant='outline'
      disabled={!connection}
      size='icon-sm'
      onClick={triggerGeneration}
      data-tip={connection ? 'Generate meeting notes' : 'Connecting...'}
    >
      <FaMagic
        className={clsx('size-3', connection && 'text-green-700 animate-pulse')}
      />
    </Button>
  );
};
